import React from "react"
import { graphql, Link } from "gatsby"
import { MDXProvider } from "@mdx-js/react"
import { MDXRenderer } from "gatsby-plugin-mdx"
// import styled from "styled-components"
import Layout from "./page-layout"

const shortcodes = { Link }

export default function PageTemplate({ data: { mdx }, location }) {
  return (
    <Layout location={location.pathname}>
      <MDXProvider components={shortcodes}>
        <MDXRenderer>{mdx.body}</MDXRenderer>
      </MDXProvider>
    </Layout>
  )
}

export const pageQuery = graphql`
  query PageQuery($id: String) {
    mdx(id: { eq: $id }) {
      id
      body
      slug
      frontmatter {
        title
      }
      tableOfContents
    }
  }
`
